.App {
  text-align: center;
}

a {
  text-decoration: none !important;
}

:root {
  /* Theme colors */
  --gd: #268b47;
  --gl: #99cba2;
  --yl: #e4b74f;
  --gr: #d3d3d3;
  --rd: #e67a70;

  /* Grays */
  --gr1: rgb(78, 78, 78);
  --gr2: rgb(126, 126, 126);
  --gr3: rgb(163, 163, 163);
  --gr4: rgb(204, 204, 204);
  --gr5: rgb(233, 233, 233);

  /* Shadows */
  --bs1: 0px 5px 15px #f7f7f7;
  --bs2: 0px 5px 15px #f3f3f3;
  --bs3: 0px 5px 15px #dfdfdf;
  --bs4: 0px 2px 15px #e4e4e4;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Helper styles */
.route.active .icon {
  color: white;
  transition: all 200ms;
}
.layout-page-conainer {
  height: calc(100vh - 70px);
}
.edit-model-view-btn {
  position: absolute !important;
  background-color: white !important;
}
.delete-model-img-btn {
  position: absolute !important;
}
.category-active-title {
  color: var(--gd) !important;
}
.pswp__img {
  object-fit: contain;
}
